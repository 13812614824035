/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov, Slobodan Djordjevic
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { logger } from 'redux-logger'
import traceReducer from './reducers/trace'
import {initLogs} from "./util/logs";
import {HashRouter} from 'trux';

const store = createStore(traceReducer, applyMiddleware(thunk, logger))

initLogs();

ReactDOM.render((
		<Provider store={store}>
				<HashRouter>
						<App />
				</HashRouter>
		</Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
