/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
const makeRequest = (url, method, data) => {
    // console.log('make request', url, method, data)
    let params = {
        method: method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data ? JSON.stringify(data) : null,
    }

    return new Promise((resolve, reject) => {
        fetch(url, params)
            .then(response => {
                return response.json()
            })
            .then(json => {
                resolve(json.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const get = (url) => {
    return makeRequest(url, 'GET')
}

const put = (url, data) => {
    return makeRequest(url, 'PUT', data)
}

const post = (url, data) => {
    return makeRequest(url, 'POST', data)
}


/** Playlists **/

export const readPlaylist = (playlistId) => {
    const url = `https://lapi.traceup.com/tracebot-${process.env.REACT_APP_TRACE_ENV}/tracebot/playlists?playlist_key=${playlistId}`
    return get(url)
}

export const updatePlaylist = (playlist) => {
    const url = `https://lapi.traceup.com/tracebot-${process.env.REACT_APP_TRACE_ENV}/tracebot/playlists?playlist_key=${playlist.hash_key}`
    return put(url, playlist)
}

export const readPlaylistForUser = (user) => {
    const url = `https://lapi.traceup.com/tracebot-${process.env.REACT_APP_TRACE_ENV}/tracebot/playlists?user_id=${user.user_id}`
    return post(url, { name: `${user.first_name} ${user.last_name}` })
}

export const readClips = (gameId) => {
    const url = `https://lapi.traceup.com/tracebot-${process.env.REACT_APP_TRACE_ENV}/tracebot/clips?game_id=${gameId}`
    return get(url)
}
