/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leo Khramov
 */
import React from 'react'
import AsyncSelect from 'react-select/async'
import { Element, Label } from 'trux'

/**
 * Selector element for moment tags, allows selection of multiple tags.
 * Methods for getting tags list and react to selection should be supplied from the outer component.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TagsSelector = (props) => {
    const itemStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const itemBadgeStyles = {
        backgroundColor: '#30A290',
        borderRadius: '2em',
        color: '#e3eeef',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.37em 0.5em',
        textAlign: 'center',
        marginLeft: '1em'
    };

    const customStyles = {
        control: (provided) => {
            return { ...provided, height: "50px", borderRadius: "0", borderWidth: "2px"}
        },
    };

    const itemFormatLabel = data => (
        <Element style={itemStyles}>
            <Label>{data.name}</Label>
            <Label style={itemBadgeStyles}>{data.tag_type}</Label>
        </Element>
    );

    const promiseOptions = (inputValue, callback) => {
        if(props.handleOnChange) {
            props.handleOnChange(inputValue)
                .then(data => {
                    callback(data);
                });
        } else {
            callback(props.tags)
        }
    };

    return (
        <Element className={props.className}>
            <AsyncSelect
                className="tags-selector"
                cacheOptions
                isMulti
                loadOptions={promiseOptions}
                defaultOptions
                styles={customStyles}
                value={props.selectedTags}
                onChange={props.handleInputChange}
                placeholder="Start typing to search"
                formatOptionLabel={itemFormatLabel}
                getOptionValue={item => item.game_clip_tag_id.toString()}
                autoFocus={props.focus}
                defaultMenuIsOpen={props.focus}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#37C2AC',
                        primary25: '#d2ffe4',
                    },
                })}
            />
        </Element>
    )
}
