/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import { createContext } from 'react'

export const PageContext = createContext({
    menuOpen: false,
    toggleMenu: () => {},
    rev: null,
})
export const PageProvider = PageContext.Provider
export const PageConsumer = PageContext.Consumer
