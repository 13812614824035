/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import { valueFor } from '../../util/helpers'
import { formatFullName, formatFirstName, formatLastName } from '../../util/formatters'

export const getPlaylist = (playlistId, state) => {
    let p = {
        'id': 'coach-notes',
        'title': 'Coach Notes',
        'enabled': true,
        'scopes': [],
        'event_count': 0,
        'filters': [
            { 'property': 'event.type', 'op': 'equal', 'constant': 'CoachAudio' }
        ]
    }
    for (let i = 0; i < state.game.playlists.length; i++) {
        let ply = state.game.playlists[i]
        if (ply.id === playlistId) {
            p = ply
            break
        }
    }
    return p
}

export const getAllPlaylists = (state, onlyEnabled = true) => {
    return state.game.playlists.filter((f) => {
        let keep = true
        if (onlyEnabled) {
            keep = f.enabled
        }
        return keep
    })
}

function filterOne(filter, scope)
{
    let sourceVal = valueFor(filter.property, '', scope)
    let scopeVal
    if (Object.prototype.hasOwnProperty.call(filter, 'constant')) {
        scopeVal = filter.constant
    } else if (Object.prototype.hasOwnProperty.call(filter, 'scope_property')) {
        scopeVal = valueFor(filter.scope_property, '', scope)
    } else {
        scopeVal = ''
    }

    let keep = false
    switch (filter.op) {
        case 'equal':
            //here we use == intentionally as we don't want to compare types
            keep = sourceVal == scopeVal // eslint-disable-line eqeqeq
            break
        case 'less_than':
            keep = sourceVal < scopeVal
            break
        case 'greater_than':
            keep = sourceVal > scopeVal
            break
        case 'includes':
            keep = sourceVal.includes(scopeVal)
            break
        case 'includes_one':
            keep = false
            if (Array.isArray(scopeVal)) {
                for (let sval of scopeVal) {
                    keep = keep || sourceVal.includes(sval)
                }
            }
            break
        default:
            keep = false
    }
    return keep
}

function fillEventTitles(events, playlist)
{
    // eslint-disable-next-line eqeqeq
    let scope = playlist.scope != null ? playlist.scope : {}
    let titles = playlist.event_title
    events.forEach( event => {
        scope.event = event

        //restore original title
        event.title = event.orig_title

        //find and render new title among playlist titles
        for(let i in titles)
        {
            let title = titles[i]
            if(title.required !== undefined)
            {
                let keep = true
                for(let j in title.required)
                {
                    let filter = title.required[j]
                    keep &= filterOne(filter, scope)
                }

                //One of the filters failed -> skip this title
                if(!keep) continue
            }
            let dynamicTitle = renderString(title.title, scope)
            if (dynamicTitle.trim().length > 0) {
                event.title = dynamicTitle
            }
            break
        }

        if (event.orig_type && event.orig_type === 'goalkeeper') {
            event.title = 'Action around the box'
        }
    })
}

export const filterEventsWithPlaylist = (playlist, otherFilters, events) => {
    // eslint-disable-next-line eqeqeq
    if (playlist == null) {
        return events
    }
    // example filter:
    // { "property": "event.type", "op": "equal", "constant": "FullGameVideo" }
    let filters = []
    for (let i in playlist.filters) {
        filters.push(playlist.filters[i])
    }
    for (let i in otherFilters) {
        filters.push(otherFilters[i])
    }
    // eslint-disable-next-line eqeqeq
    let scope = playlist.scope != null ? playlist.scope : {}

    let filteredEvents = events
    for (let i = 0; i < filters.length; i++) {
        let filter = filters[i]
        /*eslint-disable */
        filteredEvents = filteredEvents.filter((e) => {
            scope["event"] = e;
            return filterOne(filter, scope);
        });
        /*eslint-enable */
    }

    //if we have event title template in the playlist, let's apply it to all events titles
    //even if we erase the event.title we still have the original title in event.orig_title
    if(playlist.event_title !== undefined)
    {
        fillEventTitles(filteredEvents, playlist)
    }
    filteredEvents = filteredEvents.sort((a, b) => {
        let delta = a.time - b.time
        // if(delta === 0 || isNaN(delta)) delta = a.title < b.title ? -1 : (a.title > b.title ? 1 : 0);
        return delta
    })

    //enumerate events after sorting. this is an enumeration inside local scope
    filteredEvents.forEach((event, index) => event.display_number = index+1)

    return filteredEvents
}

export const filterHalves = (firstHalf, secondHalf, events) => {
    let filteredEvents = events.filter(e => {
        if (e.half === 1 && firstHalf) {
            return true
        }
        if (e.half === 2 && secondHalf) {
            return true
        }
        return false
    })
    return filteredEvents
}

export const generatePlaylists = (templates, coaches, players, events, goalies) => {
    if (!goalies) {
        throw(new Error('no goalies'))
    }
    let lists = []
    for (let key in templates) {
        let tmpl = templates[key]

        if (tmpl.scopes.includes('coach')) {
            let coachScope = {
                coach: {
                    id: 0,
                    name: ''
                }
            }
            lists.push(generatePlaylist(tmpl, coachScope, events))
        }
        if (tmpl.scopes.includes('player')) {
            let sublist = []
            for( let i in players)
            {
                if(players[i])
                {
                    let player = Object.assign({}, players[i], {
                        first_name: formatFirstName(players[i]),
                        last_name: formatLastName(players[i])
                    })
                    let playerGoalies = goalies.filter(g => g.id === player.id)
                    let scope = { player: player, players: players, goalies: playerGoalies}
                    sublist.push(generatePlaylist(tmpl, scope, events))
                }
            }
            //Sort by title
            sublist = sublist.sort( (a, b) => {
                return a.title.localeCompare(b.title)
            })
            lists.push.apply(lists, sublist)
        }
        if (tmpl.scopes.length === 0) {
            lists.push(generatePlaylist(tmpl, {players: players}, events))
        }
    }
    return lists
}

export const halfFilters = (showFirstHalf, showSecondHalf) => {
    let filters = []
    if (showFirstHalf && !showSecondHalf) {
        filters = [{ property: 'event.half', op: 'equal', constant: 1 }]
    } else if (showSecondHalf && !showFirstHalf) {
        filters = [{ property: 'event.half', op: 'equal', constant: 2 }]
    }
    return filters
}

function generatePlaylist(tmpl, scope, events) {
    let id = renderString(tmpl.id, scope)
    let title = renderString(tmpl.title, scope)
    let playlist = Object.assign({}, tmpl, {
        id: id,
        title: title,
        scope: scope,
        event_count: 0
    })

    let filteredEvents = filterEventsWithPlaylist(playlist, [], events)
    playlist.event_count = filteredEvents.length
    return playlist
}

function callConverter(funcName, scope)
{
    let value = ''
    let subs = funcName.split('+')
    let idx = 0
    const numerator = ['1st','2nd','3rd','4th','5th']

    switch(subs[0])
    {
        case 'max_effort':
            if(scope.event.meta.max_efforts) {
                for(let i in scope.event.meta.trace_numbers)
                {
                    if(scope.event.meta.trace_numbers[i] === scope.player.id)
                    {
                        if(scope.event.meta.max_efforts[i] === 1) {
                            value = ', max effort'
                        }
                        break
                    }
                }
            }
            break
        case 'nth_in_chain':
            for(let i in scope.event.meta.trace_numbers)
            {
                if(scope.event.meta.trace_numbers[i] === scope.player.id)
                {
                    idx = i
                    break
                }
            }
            value = numerator[idx] + ' in a ' + scope.event.meta.trace_numbers.length + ' chain'
            break
        case 'disabled thirds':
            if(scope.event.meta.thirds)
            {
                if(scope.event.meta.thirds[0] === scope.event.meta.thirds[1])
                {
                    value = `(${scope.event.meta.thirds[0]} 3rd)`
                } else
                {
                    value = `(${scope.event.meta.thirds[0]} 3rd to ${scope.event.meta.thirds[1]} 3rd)`
                }
            }
            break
        case 'chain_names':
            if(scope.players && scope.event.meta.trace_numbers) {
                for (let i in scope.event.meta.trace_numbers) {
                    let tn = scope.event.meta.trace_numbers[i]
                    let player = { first_name: `Tracer ${tn}`, last_name: '' }
                    let isGoalie = false
                    if (scope.event.meta.goalies) {
                        for (let gn of scope.event.meta.goalies) {
                            if (gn === tn) {
                                isGoalie = true
                                break
                            }
                        }
                    }
                    if (!isGoalie) {
                        for (let p of scope.players) {
                            if (p.trace_number === tn) {
                                player = p
                                break
                            }
                        }
                        if (value) value += ' \u2758 '
                        value += formatFullName(player)
                        let oldplayer = scope.player
                        for(let j=1;j<subs.length;j++)
                        {
                            scope.player = player
                            value += ' ' + callConverter(subs[j], scope)
                        }
                        scope.player = oldplayer
                    }
                }
            }
            break
        default:
            break
    }
    return value
}

/**
 * Used for rounding floating point values like distance to 1 fraction digit
 * Skips non-float values and returns them as is
 * @param value
 * @returns {*}
 */
function valueRound(value) {
    let fvalue = parseFloat(value)
    if(isNaN(fvalue)) return value
    return Math.round(fvalue*10)/10
}

function renderString(tmplStr, scope) {
    /*eslint-disable */
    let patt = /\{\{([\w\.]+)\}\}/g
    let funcPatt = /\[\[([\w\+]+)\]\]/g
    let result = tmplStr.replace(patt, (a, val) => valueRound(valueFor(val, "", scope)));
    if(result !== undefined) result = result.replace(funcPatt, (a, val) => callConverter(val, scope));
    /*eslint-enable */
    return result
}
