/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Slobodan Djordjevic
 */
import React from 'react';
import {connect } from 'react-redux';
import {withRouter} from 'trux';
import {Page, PageContent} from "../Navigation";
import './Main.css';
import PoweredByTrace from "../../components/PoweredBy";
import {Element, MomentsGrid, TeamSelector, USMap, Label} from 'trux';
import loadDBLambdaData from '../../actions/loadDBLambdaData';
import getLeagueName from '../../actions/getLeagueName';

class MainComponent extends React.Component {
    state = {
        leagueName: "-",
        clips: [],
        selectedTeam: null
    };

    constructor(props) {
        super(props);
        this.teamsUpdated = React.createRef();
    }

    componentDidMount() {
        this.props.getLeagueName('webex_name_by_' + this.props.type + '_slug', {slug: this.props.slug});
        this.props.fetchData('teams_list', 'webex_team_search_by_' + this.props.type + '_slug', {slug: this.props.slug});
        this.props.fetchData('latest_goals', 'webex_latest_goals_by_' + this.props.type + '_slug', {slug: this.props.slug, limit: 99});
        this.props.fetchData('map_data', 'webex_games_played_lonlat_by_' + this.props.type + '_slug', {slug: this.props.slug});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.clips !== this.props.clips) {
            let data = this.props.clips;
            data.forEach(item => item.meta = JSON.parse(item.meta));
            this.setState({clips: data, leagueName: this.props.leagueName});

            console.log('Error: ', this.props.error);
        }
    }

    onTeamSelected(team) {
        this.setState({selectedTeam: team})
        this.props.history.push(this.props.location.pathname.replace(/\/$/,'') + `/team/${team.team_id}/games`);
    }

    handleOnChange(input) {
        return new Promise(resolve => this.props.fetchData('teams_list', 'webex_team_search_by_' + this.props.type + '_slug', {slug: this.props.slug}, input, resolve));
    }

    render() {
        return (
          <Page
            className="league-main-page"
            title={[this.state.leagueName, "Overview"]}
          >
              {!this.props.error ?
                <PageContent className="full-width league-page-content">
                    <Element className="map-content">
                        <USMap data={this.props.mapData}
                               gamesListPageUrl={this.props.location.pathname + '/games-list'}
                               loading={this.props.loadingMapData}
                               utmCampaign={this.props.utmCampaign}
                               utmSource={this.props.utmSource}
                               utmMedium={'game'}/>
                    </Element>
                    <TeamSelector
                      className="team-selector-holder"
                      selectedTeam={this.state.selectedTeam}
                      handleInputChange={team => this.onTeamSelected(team)}
                      handleOnChange={input => this.handleOnChange(input)}
                      focus={this.props.findFocus}
                      teams={this.props.teams}/>
                    <Element className="bottom-content">
                        <Label type={'heading3'}>Latest goals</Label>
                        <MomentsGrid showEntries={9}
                                     prevTitle="Previous goals"
                                     nextTitle="Next goals"
                                     loading={this.props.loadingLatestGoals}
                                     clips={this.state.clips}
                                     utmCampaign={this.props.utmCampaign}
                                     utmSource={this.props.utmSource}
                                     utmMedium={'goal'}/>
                        <PoweredByTrace/>
                    </Element>
                </PageContent> :
                <PageContent className="full-width league-page-content">Please enter appropriate type in url</PageContent>
              }
          </Page>
        );
    }
}

const mapStateToProps = (state, props) => {
    let type = props.match.params.type;
    let slug = props.match.params.slug;
    let findFocus = !props.location.state ? false : props.location.state.find_focus === "focus";
    return {
        type: type,
        slug: slug,
        findFocus: findFocus,
        teams: state.teams_list.values,
        clips: state.latest_goals.values,
        mapData: state.map_data.values,
        loadingLatestGoals: state.latest_goals.loading,
        loadingMapData: state.map_data.loading,
        leagueName: state.league_name.league,
        error: state.teams_list.error || state.latest_goals.error || state.map_data.error
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        fetchData: (name, query, queryParams, title, cb) => {
            dispatch(loadDBLambdaData(name, query, queryParams, title, cb))
        },
        getLeagueName: (query, queryParams) => {
            dispatch(getLeagueName(query, queryParams))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(MainComponent))
