/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import React, { Component } from 'react'
import { PageContext } from './PageContext'

import './TopBar.css'

class TopBar extends Component {
    static contextType = PageContext

    constructor(props) {
        super(props)

        this.pageScrollChanged = this.pageScrollChanged.bind(this)

        this.state = {
            scrollY: 0,
        }
    }

    get className() {
        let name = 'trc-topbar'

        if (this.props.sticky) {
            name += ' sticky'
        }
        if (this.props.stickyLG) {
            name += ' sticky-lg'
        }
        if (this.props.stickyMD) {
            name += ' sticky-md'
        }
        if (this.props.stickySM) {
            name += ' sticky-sm'
        }
        if (this.props.stickyXS) {
            name += ' sticky-xs'
        }

        if (this.props.peekaboo) {
            name += ' peekaboo'
        }
        if (this.props.peekabooLG) {
            name += ' peekaboo-lg'
        }
        if (this.props.peekabooMD) {
            name += ' peekaboo-md'
        }
        if (this.props.peekabooSM) {
            name += ' peekaboo-sm'
        }
        if (this.props.peekabooXS) {
            name += ' peekaboo-xs'
        }

        if (this.state.scrollY > 100) {
            name += ' show'
        }

        if (this.state.scrollY > 160) {
            name += ' scroll'
        }

        return name
    }

    componentDidMount() {
        window.addEventListener('scroll', this.pageScrollChanged)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.pageScrollChanged)
    }

    pageScrollChanged(e) {
        this.setState({
            scrollY: window.scrollY,
        })
    }

    render() {
        return (
            <header className={this.className}>
                <div className="header-content">
                    {this.props.needMenu && (<a
                        href="#menu"
                        className="menu"
                        data-trk="Top Bar:Menu Toggled:Button"
                        onClick={this.context.toggleMenu}>Menu</a>)
                    }

                    { this.props.children }
                </div>
            </header>
        )
    }
}


export default TopBar;
