/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov, Slobodan Djordjevic
 */
import React from 'react'

import traceLogo from '../../assets/img/trace-logo-grey.svg'
import traceBot from '../../assets/img/trace-bot-soccer-knee.svg'
import './PoweredBy.css'

export default class PoweredByTrace extends React.Component {
    render() {
        return (
            <div className="powered-by-trace">
                <a href="https://traceup.com/" target="_blank" rel="noopener noreferrer">
                    <div className="row">
                        <span>Powered by</span>
                        <img src={traceLogo} alt="trace"/>
                    </div>
                </a>
                <img src={traceBot} alt="trace-bot"/>
            </div>
        )
    }
}
