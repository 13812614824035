/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leo Khramov
 */
import React, {useState} from 'react';
import {Page, PageContent} from "../Navigation";
import './TagsPage.css';
import {Element, MomentsGrid, Label, Row, dbQueryByLambda, Loading, Column} from 'trux';
import {TagsSelector} from "../../components/TagsSelector";


export const TagsPage = (props) => {
    const [selectedTags, setSelectedTags] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [loadingMoments, setLoadingMoments] = useState(false)
    const [momentsList, setMomentsList] = useState([])
    const [cacheId, setCacheId] = useState(0)
    const divisionId = props.match?.params?.division_id

    /**
     * Fetch list of tags based on user input in the selector
     * @param input
     * @returns {Promise<*[]>}
     */
    const handleOnChange = async (input) => {
        let data = [];
        try {
            if (selectedTags.length) {
                data = await dbQueryByLambda("webex_search_tags_sublist", {tag_id: selectedTags[0].game_clip_tag_id, search: `%${input}%`})
            } else {
                if(divisionId) {
                    data = await dbQueryByLambda("webex_search_tags_list_by_div", {search: `%${input}%`, division_id: divisionId})
                } else {
                    data = await dbQueryByLambda("webex_search_tags_list", {search: `%${input}%`})
                }
            }
        } catch (e) {
            console.log(e)
        }
        setTagsList(data)
        return data
    }

    /**
     * Fetch the moments by a given tags list
     * @param tags
     * @returns {Promise<null>}
     */
    const loadMoments = async (tags) => {
        //no tags - no moments
        if(!tags) {
            setMomentsList([])
            return null
        }

        setLoadingMoments(true)
        let data = [];
        try {
            const tagIdList = tags.map(it => it.game_clip_tag_id)
            data = await dbQueryByLambda("webex_search_moments_list",
                {
                    tags_list: tagIdList,
                    list_length: tags.length,
                    first: tagIdList[0]
                })
            for(let idx in data) {
                data[idx].meta = JSON.parse(data[idx].meta);
            }
        } catch (e) {
            console.log("Loading moments error", e)
        }
        setLoadingMoments(false)
        setMomentsList(data)
    }

    /**
     * Set list of tags selected by the user and start fetching the moments
     * @param tags
     */
    const handleInputTagsChange = (tags) => {
        //if first tag was removed we clear them all
        if(tags && tags[0].tag_type === 'tag') tags = [];

        setSelectedTags(tags || [])
        setTagsList([])
        setCacheId(cacheId+1)
        loadMoments(tags).then(()=>{})
    }

    return (
      <Page
        className="tags-page"
        title={["Tags Search"]}
      >
            <PageContent className="full-width league-page-content">
                <Label type={'heading2'} center marginTop="8">Tags Search Prototype</Label>
                <Row center marginTop="8">
                <TagsSelector
                  key={"tags-selector-" + cacheId}
                  className="tags-selector-holder"
                  selectedTags={selectedTags}
                  handleInputChange={tags => handleInputTagsChange(tags)}
                  handleOnChange={input => handleOnChange(input)}
                  tags={tagsList}/>
                </Row>
                <Element className="bottom-content">
                    <Row center>
                        <Label type={'heading3'}>Search Results</Label>
                        {loadingMoments &&
                            <Column center justifyContent="center" marginLeft="3" marginTop="2">
                                <Loading size={32}/>
                            </Column>
                        }
                    </Row>
                    <MomentsGrid showEntries={9}
                                 nextTitle="Next"
                                 clips={momentsList}
                                 utmCampaign={props.utmCampaign}
                                 utmSource={props.utmSource}
                                 utmMedium={'search'}/>
                </Element>
            </PageContent>
      </Page>
    );
}
