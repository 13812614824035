/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import React, { Component } from 'react'

export default class PageContent extends Component {
    render() {
        return (
            <section className={`trc-page-content ${this.props.className}`}>
                {this.props.children}
            </section>
        )
    }
}
