/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import React from 'react'
import loadingImg from '../assets/img/ball-triangle-loader-gray.svg'

const Loading = (props) => {
    return (
        <div className="Loading">
            <img src={loadingImg} alt="loading" />
        </div>
    )
}

export default Loading
