/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import React, { Component } from 'react'

import { PageProvider } from './PageContext'

export default class Page extends Component {

    constructor(props) {
        super(props)

        this.state = {
            menuOpen: false,
        }

        this.toggleMenu = this.toggleMenu.bind(this)
    }

    setPageTitle(titles) {
        let sep = '\u203A'
        if (titles) {
            let title = ''
            if (Array.isArray(titles)) {
                title = titles.join(` ${sep} `)
            } else {
                title = titles
            }
            title += ' ' + sep + ' Trace 37'
            document.title = title
        }
    }

    setPageDesc(desc) {
        let meta = document.querySelector("head meta[name=description]")
        if (meta) {
            meta.content = desc
        }
    }

    toggleMenu(e) {
        if (e) { e.preventDefault() }

        var show = this.state.menuOpen
        if (show) {
            show = false
            this.lockBody(false)
        } else {
            show = true
            this.lockBody(true)
        }
        this.setState({ menuOpen: show })
    }

    lockBody(lock) {
        let names = document.body.className.split(" ")
        names = names.filter(n => n.length > 0 && n !== 'locked')
        if (lock) {
            names.push('locked')
        }
        document.body.className = names.join(' ')
    }

    openMenu(open) {
        let menu = document.querySelector(`.${this.props.className} .trc-page-menu`)
        let names = menu.className.split(" ")
        names = names.filter(n => n.length > 0 && (n !== 'open' || n !== 'closed'))
        if (open) {
            names.push('open')
        } else {
            names.push('closed')
        }
        menu.className = names.join(' ')
    }



    render() {
        let context = {
            toggleMenu: this.toggleMenu,
            menuOpen: this.state.menuOpen,
            rev: new Date(),
        }

        if (this.props.title) {
            this.setPageTitle(this.props.title)
        }
        if (this.props.description) {
            this.setPageDesc(this.props.description)
        }

        return (
            <div className={`trc-page ${this.props.className}`}>
                <PageProvider value={context}>
                    {this.props.children}
                </PageProvider>
            </div>
        )
    }
}



