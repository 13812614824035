/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov, Slobodan Djordjevic
 */
const initialState = {
    settings: {
        loading: false,
        complete: true,
        error: null,
        value: {
            canEdit: false,
            isLoggedIn: false,
        }
    },
    user: {
        loading: false,
        complete: false,
        error: null,
        value: null,
    },
    toast: {
        value: ''
    },
    matches_list: {
        loading: false,
        complete: false,
        error: null,
        values: []
    },
    teams_list: {
        loading: false,
        complete: false,
        error: null,
        values: []
    },
    latest_goals: {
        loading: false,
        complete: false,
        error: null,
        values: []
    },
    map_data: {
        loading: false,
        complete: false,
        error: null,
        values: []
    },
    league_name: {
        league: '',
        error: null
    }
}

const traceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE':
            let section = { ...state[action.key] }
            if (action.loading !== undefined) {
                section.loading = action.loading
            }
            if (action.complete !== undefined) {
                section.complete = action.complete
            }
            if (action.error !== undefined) {
                section.error = action.error
            }
            if (action.pending !== undefined) {
                section.pending = action.pending
            }

            if (action.value !== undefined) {
                section.value = action.value
            }
            let update = {}
            update[action.key] = section
            let next = Object.assign({}, state, update)
            updateSettings(next)
            return next

        case 'COMMON_LOAD':
            let newState = {}
            newState[action.key] = {
                loading: action.value.loading,
                complete: action.value.complete,
                error: action.value.error,
                values: action.value.values
            }
            return Object.assign({}, state, newState)
        case 'GET_LEAGUE_NAME':
            let leagueName = {}
            leagueName.league_name = {
                league: action.value.league,
                error: action.value.error
            }
            return Object.assign({}, state, leagueName)
        default:
            return state
    }
}

const updateSettings = (next) => {
    next.settings.value.isLoggedIn = next.user.value !== null
    next.settings.value.canEdit = false
}

export default traceReducer
