/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'


class ModalPageComponent extends Component {

    constructor(props) {
        super(props)

        this.onClosePressed = this.onClosePressed.bind(this)
        this.onBackPressed = this.onBackPressed.bind(this)
    }
    
    onClosePressed(e) {
        if (e) { e.preventDefault() }
        if (this.props.onClosePressed) {
            this.props.onClosePressed()
        }
    }

    onBackPressed(e) {
        if (e) { e.preventDefault() }
        if (this.props.onBackPressed) {
            this.props.onBackPressed()
        }
    }

    render() {
        return (
            <div className="trc-modal-page">
                <div className="trc-modal-page-header">
                    { !this.props.showBack && ( 
                        <a href="#close" className="back" onClick={this.onBackPressed}>Close</a>
                    )}
                    { this.props.showBack && ( 
                        <div className="empty-button" />
                    )}
                    <h1>{this.props.title}</h1>
                    { !this.props.showClose && (
                        <a href="#close" className="close" onClick={this.onClosePressed}>Close</a>
                    )}
                    { this.props.showClose && ( 
                        <div className="empty-button" />
                    )}
                </div>
                <section className="trc-page-content">
                    {this.props.children}
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, cProps) => {
    return {
        divisions: state.divisions.value ? state.divisions.value : [],
        user: state.user.value,
        rev: new Date().getTime()
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export const ModalPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalPageComponent)



