/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov, Slobodan Djordjevic
 */
import React, { Component } from 'react';
import {Route} from 'trux';
import ReactGA from 'react-ga';

import 'trux/dist/reset.css';
import 'trux/dist/trux.css';
import './App.css'
import TeamGames from "./modules/TeamGames";
import GamesList from './modules/GamesList';
import ChoosePlaylist from "./components/ChoosePlaylist";
import {default as Main} from './modules/Main/index';
import {startEventTracking} from './util/Track';
import LiveStream from "./modules/LiveStream";
import {TagsPage} from "./modules/TagsPage";

console.log('')
console.log('WSOCCERGAMERESULTSANDPERSONALIZEDMOMENTSFOREVERYPLAYER#GOODBYECAMERAMANHELLOTRACE')
console.log('HSOCCERGAMERESULTSANDPERSONALIZEDMOMENTSFOREVERYPLAYER#GOODBYECAMERAMANHELLOTRACE')
console.log('TT                MM                                                           NN')
console.log('RR                OO                                                           OO')
console.log('AA       TTT      MM     TTTTTT    RRRRRR       AAA        CCCC    EEEEEE      TT')
console.log('CC       TTT      EE       TT      RR   RR     AAAAA      CC  C    EE          CC')
console.log('EE                NN       TT      RRRRRR      AA AA     CC        EEEEEE      LL')
console.log('BB    RRR  CCC    TT       TT      RR  RR      AAAAA     C         EE          II')
console.log('OO    RRR  CCC    SS       TT      RR  RR     AA   AA    CC  CC    EE          PP')
console.log('TT                YY       TT      RR  RR     AA   AA      CC      EEEEEE      SS')
console.log('HH                AA                                                           42')
console.log('II                YY                                                           42')
console.log('RSOCCERGAMERESULTSANDPERSONALIZEDMOMENTSFOREVERYPLAYER#GOODBYECAMERAMANHELLOTRACE')
console.log('TSOCCERGAMERESULTSANDPERSONALIZEDMOMENTSFOREVERYPLAYER#GOODBYECAMERAMANHELLOTRACE')
console.log('')
console.log('\tWe are hiring:  https://www.traceup.com/careers')
console.log('')

const GA_CONFIG = {
    trackingId: 'UA-51503888-7',
    debug: false,
    gaOptions: {
        cookieDomain: window.location.hostname
    }
}

ReactGA.initialize([GA_CONFIG])
startEventTracking()

const App = () => {
    return (
        <div className="App">
            <Route path="/" component={PageViewChange} />
            <Route path="/:type/:slug" exact >
                <Main utmCampaign={'37'} utmSource={'trace'}/>
            </Route>
            <Route path="/:type/:slug/games-list" >
                <GamesList utmCampaign={'37'} utmSource={'trace'}/>
            </Route>
            <Route path="/:type/:slug/team/:team_id/games" >
                <TeamGames utmCampaign={'37'} utmSource={'trace'}/>
            </Route>
            <Route path="/:type/:slug/team/:team_id/games/jump/:team_id/:game_id" >
                <ChoosePlaylist utmCampaign={'37'} utmSource={'trace'}/>
            </Route>
            <Route path="/stream/:team_id/:game_id" >
                <LiveStream/>
            </Route>
            <Route path="/tags-search" exact >
                <TagsPage utmCampaign={'37'} utmSource={'trace'}/>
            </Route>
            <Route path="/tags-search/division/:division_id" exact
                   component={TagsPage}
            >
            </Route>
        </div>
    )
}

export default App

class PageViewChange extends Component {

    constructor(props) {
        super(props)
        this.lastUrl = null
    }

    componentDidMount() {
        this.onChange()
    }

    componentDidUpdate() {
        this.onChange()
    }

    onChange() {
        if (this.lastUrl !== this.props.location.pathname) {
            this.lastUrl = this.props.location.pathname
            ReactGA.pageview(this.lastUrl)
        }
    }

    render() {
        return null
    }
}
