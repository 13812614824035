/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */

export const pageTitle = (titles) => {
    let sep = '\u203A'
    if (titles) {
        let title = ''
        if (Array.isArray(titles)) {
            title = titles.join(` ${sep} `)
        } else {
            title = titles
        }
        title += ' ' + sep + ' Trace iD'
        document.title = title
    } else {
        return document.title
    }
}

export const valueFor = (key, def, from) => {
    var value = def

    var dots = key.split('.')
    var scope = from
    for (var i = 0; i < dots.length; i ++) {
        var dot = dots[i]
        try {
            scope = scope[dot]
        } catch(err) {
            // console.error(err)
            // console.error("key", key, "from", from);
            return def
        }
    }
    if (scope != null) {
        value = scope
    }

    return value
}

export const fullName2FirstLast = (fullName) => {
    let firstName = fullName.trim()
    let lastName = ''
    const nameparts = firstName.split(' ')
    if(nameparts.length > 0) {
        lastName = capFirst(nameparts.pop())
        firstName = capFirst(nameparts.join(' '))
    }

    return {first_name: firstName, last_name: lastName}
}

export const capFirst = (name) => {
    if(!name) return name;
    return name[0].toUpperCase() + name.substring(1);
}

export const validateNumber = (val) => {
    if(!val || !val.trim()) return null;
    let regex = /^[\d\.]+?$/   // eslint-disable-line no-useless-escape
    let res = regex.test(val)
    if (res) {
        return null
    }
    return 'Must be a number'
}

export const validateNonEmpty = (val) => {
    if (val.length > 0) {
        return null
    }
    return 'Value is required'
}

export const isMobile = () => {
    return window.matchMedia("only screen and (max-width: 760px)").matches;
};


