/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import {readClips} from "./playlistApi";
import {formatPlayerUnknown} from "../../util/formatters";
import {filterEventsWithPlaylist, generatePlaylists} from "./playlistStore";
import {webApi} from "../WebApi";

const apiEndpoint = process.env.REACT_APP_API

function fetchGameV2(teamFolder, teamName, gameId) {
    return new Promise((resolve, reject) => {
        let gameUrl = `${apiEndpoint}/teams/${teamFolder}/games/${teamName}-${gameId}/game_v2.json`

        fetch(gameUrl)
            .then(response => response.json())
            .then(json => {
                resolve({ type:'v2', data: json })
            })
            .catch(err => {
                resolve({ type: 'v2', data: null })
            })
    })
}

function fetchGameInfo(teamFolder, teamName, gameId) {
    return new Promise((resolve, reject) => {
        let infoUrl = `${apiEndpoint}/teams/${teamFolder}/games/${teamName}-${gameId}/game_info.json`

        fetch(infoUrl)
            .then(response => response.json())
            .then(json => {
                resolve({ type:'info', data: json })
            })
            .catch(err => {
                resolve({ type: 'info', data: null })
            })
    })
}


function fetchGameTimeline(teamFolder, teamName, gameId) {
    return new Promise((resolve, reject) => {
        let url = `${apiEndpoint}/teams/${teamFolder}/games/${teamName}-${gameId}/timeline.json`
        return fetch(url)
            .then(response => {
                let contentType = response.headers.get('content-type')
                if(contentType && contentType.includes('application/json')) {
                    return response.json()
                } else {
                    return null
                }
            })
            .then(json => {
                if (!json) {
                    json = { spotlight: [] }
                }
                resolve(json)
            })
            .catch(err => {
                console.error(err)
                resolve({ spotlight: [] })
            })
    })
}



const loadPlaylists = (teamName) => {
    return new Promise((resolve, reject) => {
        let globalUrl = `${apiEndpoint}/playlists.json`
        let teamUrl = `${apiEndpoint}/teams/${teamName}/playlists.json`

        fetchPlaylist(globalUrl, (globalPlaylist) => {
            fetchPlaylist(teamUrl, (teamPlaylist) => {
                let playlists = Object.assign({}, globalPlaylist, teamPlaylist)
                resolve(playlists)
            })
        })
    })
}

function fetchPlaylist(url, callback) {
    fetch(url)
        .then(response => response.json())
        .then(json => {
            callback(json)
        })
        .catch(err => {
            callback({})
        })
}

function processEvents(actionEvents, goalies) {
    let allEvents = actionEvents.sort((a, b) => {
        let res = 0
        if (a.type === b.type) {
            res = a.time - b.time
        } else if (a.type === 'CoachAudio') {
            res = -1
        } else if (b.type === 'CoachAudio') {
            res = 1
        } else {
            res = a.time - b.time
        }
        return res
    })
    let i = 0
    for (let event of allEvents) {
        event['event_number'] = i + 1

        //copy original title to orig_title as we can redefine 'title' value later
        event['orig_title'] = event['title']

        // add goalies to tracer numbers
        if (!event.meta.trace_numbers) {
            event.meta.trace_numbers = []
        }

        // change goalkeeper events to touch goalkeeper
        if (event.meta.type === 'goalkeeper') {
            event.orig_type = 'goalkeeper'
            event.meta.type = 'touch chain goalkeeper'
        }

        event.meta.goalies = []
        if (event.meta && event.meta.type.includes('key') && event.meta.thirds && event.meta.thirds.includes('defensive')) {
            for (let g of goalies) {
                let gStart = g.utc_start_time * 1000
                let gEnd = g.utc_end_time * 1000
                if (gStart < event.utc_time && event.utc_time < gEnd) {
                    event.meta.trace_numbers.push(g.id)
                    event.meta.goalies.push(g.id)
                }
            }
        }
        if (event.meta && event.meta.type.includes('goalkeeper')) {
            for (let g of goalies) {
                let gStart = g.utc_start_time * 1000
                let gEnd = g.utc_end_time * 1000
                if (g.id && gStart < event.utc_time && event.utc_time < gEnd) {
                    event.meta.trace_numbers.push(g.id)
                    event.meta.goalies.push(g.id)
                }
            }
        }

        i++
    }
    return allEvents
}


function updateEventsWithProfilePlaylist(user, events) {
    return new Promise((resolve, reject) => {
        let playlist = user.traceid_playlist
        if (playlist && Array.isArray(playlist.clips)) {
            for (let clip of playlist.clips) {
                for (let event of events) {
                    if (clip.game_clip_id === event.game_clip_id) {
                        event.user_playlist_key = playlist.hash_key
                        event.playlist_clip_id = clip.playlist_clip_id
                    }
                }
            }
        }
        resolve(events)
    })
}


function updateEventsWithClips(gameId, events) {
    return new Promise((resolve, reject) => {
        readClips(gameId)
            .then(clips => {
                for (let clip of clips) {
                    for (let event of events) {
                        if (clip.utc_time === event.utc_time && (clip.duration - event.duration) < 0.001) {
                            event.game_clip_id = clip.game_clip_id
                            event.user_playlist_key = null
                            break
                        }
                    }
                }

                resolve(events)
            })
    })
}

export async function loadGame(teamName, gameId) {
    let teamFolder = teamName;
    let user = null; //FIXME later should be filled with logged-in user

    return Promise.all([
        fetchGameV2(teamFolder, teamName, gameId),
        fetchGameInfo(teamFolder, teamName, gameId),
        fetchGameTimeline(teamFolder, teamName, gameId),
        loadPlaylists(teamFolder),
        webApi.getGame(gameId)
    ])
        .then(results => {
            let game = {
                game_id: gameId,
                processing: true,
                away_team: {},
                home_team: {},
                events: []
            }

            //console.log('results', results)

            if (!results[0].data || !results[1].data) {
                console.log('game not ready')
                game.processing = true
                return game
            }

            game.processing = false
            let json = results[0].data
            let gameJson = JSON.parse(JSON.stringify(json.game))
            delete json.game
            game = Object.assign({}, game, json, gameJson)

            game = Object.assign({}, game, results[1].data, results[4])

            game.timeline = results[2]

            game.playlistTemplates = results[3]

            console.log('loadGame after v2, info, timeline', game)
            return game
        })
        .then(async (game) => {
            let goalies = game.goalies ? game.goalies : []

            game.events = processEvents(game.events, goalies)
            game.events = await updateEventsWithClips(game.game_id, game.events)
            if (user) {
                game.events = await updateEventsWithProfilePlaylist(user, game.events)
            }
            console.log('loadGame after v3, info, timeline', game)
            return game
        })
        .then(game => {
            // fixup players
            for (let p of game.home_team.players) {
                p.id = p.trace_number
                p.unknown = formatPlayerUnknown(p)
            }
            game.team_folder = teamFolder
            return game
        })
        .then(game => {
            let goalies = game.goalies ? game.goalies : []
            let templates = game.playlistTemplates

            let playlists = generatePlaylists(templates, [], game.home_team.players, game.events, goalies)
            for (let p of playlists) {
                let pe = filterEventsWithPlaylist(p, [], game.events)
                playlists.event_count = pe.length
            }

            game.playlists = playlists.filter(p => {
                if (p.id === 'coach-notes') {
                    return p.event_count > 1
                } else {
                    return p.event_count > 0
                }
            })
            return game
        })
}
