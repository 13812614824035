/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import { datadogLogs } from '@datadog/browser-logs';

let sendConsoleLogs = false;

export const initLogs = (sendLogs = false) => {
    sendConsoleLogs = sendLogs;
    datadogLogs.init({clientToken: 'pubf51689f1928d6237350b519341af107a',
        datacenter: 'us',
        forwardErrorsToLogs: true,
        sampleRate: 100
    });

    datadogLogs.addLoggerGlobalContext('application_id', 'experiments_webapp');

    let hostParts = document.location.hostname.split(".");
    let env = hostParts[0].replace('host', '');
    //if we have raw ip address then assume we are testing locally from other machine.
    if(parseInt(hostParts[0], 10)) env = 'local';
    datadogLogs.addLoggerGlobalContext('environment', env);
    addUserInfo(null);

    if(sendLogs) {
        (function () {
            let oldLog = console.log;
            console.log = function (...args) {
                if (args.length) {
                    datadogLogs.logger.info(args)
                }
                oldLog.apply(console, args);
            };
        })();
    }
}

export const addUserInfo = (user) => {
    datadogLogs.addLoggerGlobalContext('user_id', user ? `${user.user_id}` : '');
    datadogLogs.addLoggerGlobalContext('user_name', user ? `${user.first_name} ${user.last_name}` : '');
    if(user) {
        datadogLogs.logger.info(`Setting user account info for '${user.first_name} ${user.last_name}', id=${user.user_id}`);
    }
};

/**
 * Logs everything to remote logger and console.
 * @param message string - message to log
 * @param args optional parameters to be added to the message
 */
export const rlog = (message, ...args) => {
    let msg = message;
    if(args && args.length) {
        msg += ' ' + JSON.stringify(args);
    }
    datadogLogs.logger.info(msg);
    if(!sendConsoleLogs) {
        console.log(message, ...args);
    }
};

/**
 * Log message to remote logger and add tags to it
 * @param message string message to send to the remote logger and console
 * @param args object key:value object to be sent as an additional attributes to the remote logger
 */
export const tlog = (message, args) => {
    datadogLogs.logger.info(message, args);
    if(!sendConsoleLogs) {
        console.log(message, args);
    }
};

/**
 * Send error to the remote logger and add tags to it
 * @param message string message or object
 * @param args tags object with key: value pairs
 */
export const terror = (message, args) => {
    datadogLogs.logger.error(message, args);
    if(!sendConsoleLogs) {
        console.error(message, args);
    }
};
