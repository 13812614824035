/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import React from "react";
import {Page, PageContent} from '../Navigation';
import {Column, MomentPreview, RoundButton, Icon, Color, Row, Label, Element} from 'trux';
import {withRouter} from 'trux';
import './LiveStream.css'

class LiveStreamComponent extends React.Component {

    constructor(props) {
        super(props);
        this.momentRef = React.createRef()
        this.state = { paused: true, streamKey: 'initial' }
        this.timer = null
    }

    togglePlay(e) {
        if(e) e.preventDefault();
        console.log("Moment ref", this.momentRef);

        if(this.state.paused) {
            this.startStreamCheck();
            this.momentRef.current.play();
        } else {
            this.stopSteamCheck();
            this.momentRef.current.pause();
        }
        this.setState({paused: !this.state.paused})
    }

    loadStreamkey() {
        let url = `${process.env.REACT_APP_QAAPI}/teams/${this.props.match.params.team_id}/games/${this.props.match.params.team_id}-${this.props.match.params.game_id}/stream/live.m3u8`
        new Promise((resolve, reject) => {
            fetch(url)
                .then(response => {
                    return response.text()
                })
                .then(text => {
                    resolve(text)
                })
                .catch(err => {
                    resolve("")
                })
        }).then(text => this.processM3U8(text))
    }

    componentDidMount() {
        this.loadStreamkey();
    }

    startStreamCheck() {
        this.loadStreamkey();
        this.timer = setInterval(()=>{
            this.loadStreamkey()
        }, 4000)
    }

    stopSteamCheck() {
        if(this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    processM3U8(text) {
        let lines = text.split('\n');
        let lastLine = '';
        for(let line of lines) {
            if(line.startsWith("live-")) lastLine = line;
        }
        let parts = lastLine.split('-');
        if(parts.length > 2) {
            let key = parts[1];
            //console.log("Found stream key:", key);
            if(this.state.streamKey !== key) {
                console.log(`Stream key has changed ${this.state.streamKey} -> ${key}, restarting the player`);
                this.setState({streamKey: key})
                if(!this.state.paused) {
                    setTimeout(()=>{
                        this.momentRef.current.togglePlay();
                    }, 300)
                }
            }
        }
    }

    render() {
        let moment = {
            meta:{ home_team: {id: this.props.match.params.team_id}},
            game_id: this.props.match.params.game_id,
            dynamic_hls: "stream/live.m3u8"
        }
        return (
            <Page
                className="live-stream-page"
                title={'Trace Live Stream'}
            >
                <PageContent className="live-stream-content">
                    <Column justifyContent={"center"} alignItems={"center"}>
                        <MomentPreview
                            ref={this.momentRef}
                            key={this.state.streamKey}
                            event={moment}
                            playbackRate={1}
                            eventCategory={'LiveStream'}
                            videoLevel={-1}
                            autoPlay={false}
                            noHoverPlay={true}
                            onClick={(e)=>this.togglePlay(e)}
                            apiEndpoint={process.env.REACT_APP_QAAPI}
                        />
                        <Row justifyContent={"space-between"} width={"100%"}>
                        <RoundButton
                            iconSize={24}
                            size={48}
                            color={Color.Trace}
                            onClick={(e) => this.togglePlay(e)}
                            icon={this.state.paused ? Icon.Type.Play : Icon.Type.Pause}
                            margin="4"
                        />
                            <Row alignItems={"center"}>
                                <Element  className={`stream-state-${!this.state.paused}`} marginRight="2">
                                    <Icon type={Icon.Type.Sun} size={12} color={this.state.paused ? Color.Grey1 : Color.Error}/>
                                </Element>
                                <Label type={Label.Type.Heading5} content={`Trace Live Stream`} marginRight="4" color={Color.Grey4}/>
                            </Row>
                        </Row>
                    </Column>
                </PageContent>
            </Page>
        );
    }
}

const LiveStream = withRouter(LiveStreamComponent);
export default LiveStream;
