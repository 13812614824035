/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Slobodan Djordjevic
 */
import {dbQueryByLambda} from 'trux';


const getLeagueName = (query, queryParams) => {
  return (dispatch, getState) => {
    const loadingState = getState().league_name

    if (loadingState.loading) {
      return { type: 'NOOP'}
    }

    dispatch({
      type: 'GET_LEAGUE_NAME',
      value: {
        league: '',
        error: null
      }
    });

    dbQueryByLambda(query, queryParams)
      .then(data => {
        dispatch({
          type: 'GET_LEAGUE_NAME',
          value: {
            league: data[0].title,
            error: null,
          }
        });
      })
      .catch(error => {
        console.error('GET_LEAGUE_NAME error: ', error)
        dispatch({
          type: 'COMMON_LOAD',
          value: {
            league: '',
            error: error
          }
        })
      });
  }
}

export default getLeagueName;
