/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import React, { Component } from 'react'
import { PageContext } from './PageContext'

class Menu extends Component {
    static contextType = PageContext

    get title() {
        var val = 'Menu'
        if (this.props.title) {
            val = this.props.title
        }
        return val
    }

    get className() {
        var val = 'trc-page-menu'
        if (this.context.menuOpen) {
            val += ' open'
        }
        if (this.context.menuOpen === false) {
            val += ' closed'
        }
        return val
    }

    render() {
        return (
            <section className={this.className}>
                <header>
                    <h2>{this.title}</h2>
                    <a
                        href="#close"
                        className="close"
                        onClick={this.context.toggleMenu}
                    >
                        Close
                    </a>
                </header>
                <div className="trc-page-menu-content">
                    {this.props.children}
                </div>
            </section>
        )
    }

}

export default Menu;
