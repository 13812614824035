/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */
import { post, put, get } from '../actions/api'
import ReactGA from 'react-ga'

const SEGMENT_DURATION = 4

export const trkSetDefaultValue = (val) => {
    window.trk_value = val
    console.log('trackEvent default', val)
}

export const trk = (event, withPageView) => {
    let defaultValue = window.trk_value ? window.trk_value : -1
    let parts = event.split(':')
    let cat = parts.length > 0 ? parts[0] : null
    let act = parts.length > 1 ? parts[1] : null
    let lbl = parts.length > 2 ? parts[2] : null
    let val = parts.length > 3 ? parts[3] : defaultValue
    //console.log('track trk', cat, act, lbl, val, defaultValue, window.trk_value)

    if (withPageView && cat) {
        let url = `/#/fun/events/${cat.replace(/\s/gi, '')}`
        if (act) {
            url += `/${act.replace(/\s/gi, '')}`
        }
        if (lbl) {
            url += `/${lbl.replace(/\s/gi, '')}`
        }
        trackPage(url)
    }

    if (cat) {
        trackEvent(cat, act, lbl, val)
    }
}

export const trackPage = (url) => {
    //console.log('trackPage', url)
    ReactGA.pageview(url)
}

export const trackEvent = (category, action, label, value = -1) => {

    let val
    if (window.trk_value && value === -1) {
        val = window.trk_value
    }
    else if (value) {
        val = value
    }

    if (window.fbq && category && action) {
        let fbCat = category.replace(/[^a-zA-Z-0-9]/gi, ' ')
        let fbAct = action.replace(/[^a-zA-Z-0-9]/gi, ' ')
        window.fbq('trackCustom', `${fbCat}_${fbAct}`)
    }

    //console.log('trackEvent', category, action, label, val)

    ReactGA.event({
        category,
        action,
        label,
        val
    })
}

export const startEventTracking = () => {
    document.addEventListener('click', globalEventTracker)
}

export const stopEventTracking = () => {
    document.removeEventListener('click', globalEventTracker)
}

const globalEventTracker = (e) => {
    let target = null
    if (e.target) {
        target = e.target
    }
    else if (e.currentTarget) {
        target = e.currentTarget
    }
    //console.log('globalEventTracker', e, target)

    if (target) {
        let eventStr = target.getAttribute('data-trk')
        let withPageView = target.getAttribute('data-trk-page') === 'true'
        //console.log('globalEventTracker eventStr', eventStr)
        if (eventStr) {
            //console.log('globalEventTracker tracking', eventStr)
            trk(eventStr, withPageView)
        } else if (target.parentElement && target.parentElement.getAttribute('data-trk')) {
            globalEventTracker({ target: target.parentElement })
        }
    }
}

export default class Track {

    constructor(clipId, playlistId, userId) {
        this.clipId = clipId;
        this.userId = userId;
        this.playlistId = playlistId;

        this.vi = {
            currentTime: null,
            duration: null,

        }
        this.view = null
        this.saving = false
        this.pendingEvents =  null
        this.lastSegment = SEGMENT_DURATION * -1
        //console.log('Track.constructor', clipId)
    }

    update(vi) {
        this.vi = vi
        if (vi.currentTime > 0) {
            let sid = Math.floor(vi.currentTime/SEGMENT_DURATION) * SEGMENT_DURATION
            if (sid > this.lastSegment) {
                this.lastSegment = sid
                this.videoPlayedSegment(sid)
            }
        }
    }

    static async getViews(clipId, playlistId) {

        let url = `https://lapi.traceup.com/tracebot-${process.env.REACT_APP_TRACE_ENV}/tracebot/views?game_clip_id=${clipId}&playlist_id=${playlistId}`

        let views = await get(url)
        return views
    }

    async getView() {
        //console.log('Track.getView', this.view)
        if (this.view) {
            return this.view
        }

        let url = `https://lapi.traceup.com/tracebot-${process.env.REACT_APP_TRACE_ENV}/tracebot/views`

        let data = {
            viewable_id: this.clipId,
            viewable_type: 'game_clips',
            user_id: this.userId,
            group_id: this.playlistId,
            group_type: 'user_playlists',
            duration: 0,
        }

        this.saving = true
        //console.log('Track.getView loading')
        this.view = await post(url, data)
        //console.log('Track.getView loaded', this.view)
        this.saving = false
        return this.view
    }

    async event(type, video_time) {
        if (this.pendingEvents == null) {
            this.pendingEvents = []
        }
        this.pendingEvents.push({
            type,
            meta: { video_time },
        })
        //console.log("Track.event", type, video_time, this.view, this.saving)

        if (this.view && this.saving === false) {
            //console.log("Track.event sending", this.pendingEvents.length)
            this.saving = true

            let v = await this.getView()
            v.user_id = this.userId;
            v.events = this.pendingEvents
            this.pendingEvents = null

            let url = `https://lapi.traceup.com/tracebot-${process.env.REACT_APP_TRACE_ENV}/tracebot/views`
            await put(url, v)

            this.saving = false
        }

        return true
    }

    async videoCount(clipId) {
        this.clipId = clipId
        await this.getView()
    }

    videoStart() {
        this.event("start", this.vi.currentTime)
    }

    async videoPlayedSegment(sid) {
        let v = await this.getView()
        v.duration += SEGMENT_DURATION
        this.event("segment", sid)
    }

    videoEnd() {
        this.event("end", this.vi.currentTime)
    }

    videoPlay() {
        this.event("play", this.vi.currentTime)
    }

    videoPause() {
        this.event("pause", this.vi.currentTime)
    }

    videoJump() {
        this.event("jump", this.vi.currentTime)
    }

    videoActive() {
        this.event("active", this.vi.currentTime)
    }

    videoInactive() {
        this.event("inactive", this.vi.currentTime)
    }


}
