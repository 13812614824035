/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov, Slobodan Djordjevic
 */
import React from 'react'
import { connect } from 'react-redux'
import {Label, TRCDialog} from 'trux'


import './ChoosePlaylist.css'
import {loadGame} from "../../lib/playlist/playlist";
import Loading from "../Loading";
import {filterEventsWithPlaylist} from "../../lib/playlist/playlistStore";

// props
// - playlistTitle
// - gameDate
class ChoosePlaylistComponent extends TRCDialog {
    utmCodes = '';

    // Step "info", "payment", "processing", "confirmation", "error"

    constructor(props) {
        super(props)
        this.keyPressed = this.keyPressed.bind(this)
        this.state ={
            teamPlaylists: [],
            playerPlaylists: [],
            events: [],
            loading: true
        }
    }

    componentDidMount() {
        this.loadGame();
        super.componentDidMount();
        if(this.props.utmCampaign && this.props.utmSource) {
            this.utmCodes = `?utm_campaign=${this.props.utmCampaign}&utm_source=${this.props.utmSource}&utm_medium=game`;
        }
    }

    loadGame() {
        this.setState({loading: true});
        loadGame(this.props.teamId, this.props.gameId)
        .then(game => {
            if (game && !game.processing) {
                console.log("GAME:", game);
                this.createPlaylists(game);
            }
        })
    }


    createPlaylists(game) {
        let playlists = game.playlists.filter(p => {
            if(p.enabled === true) {
                let events = filterEventsWithPlaylist(p, [], game.events);
                if(events.length) {
                    p.start_event_num = events[0].event_number;
                    return true
                }
            }
            return false
        });

        let teamPlaylists = playlists.filter(p => !p.scope.player)
        let playerPlaylists = playlists.filter(p => p.scope.player)
        this.setState({teamPlaylists: teamPlaylists, playerPlaylists: playerPlaylists, game: game, loading: false});
    }

    get addClassName() {
        return 'ChoosePlaylist'
    }

    keyPressed(event) {
        switch (event.keyCode) {
            case 27: //escape
                this.close(event)
                break
            default:
                break
        }
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
    }

    get title() {
        return (
            <h2>Select a Playlist</h2>
        )
    }

    gameUrl(teamId, playlistId, gameId) {
        return ".";
    }

    openPlaylist(playlist) {
        let events = filterEventsWithPlaylist(playlist, [], this.state.game.events);
        console.log("OPENPL", playlist, events);
    }

    get content() {
        if(this.state.loading) return (
            <div className='loading'>
                <Loading />
                <Label
                    center
                    type={Label.Type.Heading3}
                    marginLeft="4"
                    marginRight="4"
                    content={`Loading playlist ...`}
                />
            </div>
        );
        return (
            <div>
                <h3>Tactical</h3>
                <ul>
                    { this.state.teamPlaylists.map(p => (
                        <li key={`chooseplaylist-item-${p.id}`} >
                            <a href={`${process.env.REACT_APP_GO_TRACEUP}#/${this.props.teamId}/games/${this.props.gameId}/${p.id}/${p.start_event_num}${this.utmCodes}`}>
                            {p.title}
                            &nbsp;
                            ({p.event_count})
                            </a>
                        </li>
                    )) }
                </ul>

                <h3>Player Moments</h3>
                <ul>
                    { this.state.playerPlaylists.map(p => (
                        <li key={`chooseplaylist-item-${p.id}`}>
                            <a href={`${process.env.REACT_APP_GO_TRACEUP}#/${this.props.teamId}/games/${this.props.gameId}/${p.id}/${p.start_event_num}${this.utmCodes}`}
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                {p.title}
                                &nbsp;
                                ({p.event_count})
                            </a>
                        </li>
                    )) }
                </ul>

            </div>
        )
    }

    get dismissUrl() {
        return false
    }

    get allowClose() {
        return true
    }
}

const mapStateToProps = (state, props) => {
    let teamId = props.match.params.team_id
    let gameId = props.match.params.game_id
    return {
        teamId,
        gameId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const ChoosePlaylist = connect(mapStateToProps, mapDispatchToProps)(ChoosePlaylistComponent)

export default ChoosePlaylist

