/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Slobodan Djordjevic
 */
import React from "react";
import {Page, PageContent} from '../Navigation';
import PoweredByTrace from '../../components/PoweredBy';
import {Button, Icon, MatchesList} from 'trux';
import {withRouter} from 'trux';
import './GameList.css'

class GamesListComponent extends React.Component {

    render() {
        let games = this.props.location.state && this.props.location.state.games ?
                    this.props.location.state.games : [];
        let loading = this.props.location.state && this.props.location.state.loading;
        return (
            <Page
                className="team-games-page"
                title={'Games list page'}
            >
                <PageContent>
                    <div className="team-games-content">
                        <div className="back-button">
                            <Button icon={Icon.Type.ArrowLeft} onClick={() => this.props.history.goBack()}>Back</Button>
                        </div>
                        <MatchesList loading={loading}
                                     games={games}
                                     utmCampaign={this.props.utmCampaign}
                                     utmSource={this.props.utmSource}
                                     utmMedium={'game'} />
                        <PoweredByTrace/>
                    </div>
                </PageContent>
            </Page>
        );
    }
}

const GamesList = withRouter(GamesListComponent);
export default GamesList;
