/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Slobodan Djordjevic
 */
import {dbQueryByLambda, multisportify} from 'trux';


const loadDBLambdaData = (key, query, queryParams, title = '', cb = null) => {
  return (dispatch, getState) => {
    const loadingState = getState()[key]

    if (loadingState.loading) {
      return { type: 'NOOP'}
    }

    dispatch({
      type: 'COMMON_LOAD',
      key: key,
      value: {
        loading: true,
        complete: false,
        error: null,
        values: []
      }
    });

    dbQueryByLambda(query, { title: `%${title}%`, ...queryParams})
      .then(dataFetched => {
        let data = multisportify(dataFetched)
        dispatch({
          type: 'COMMON_LOAD',
          key: key,
          value: {
            loading: false,
            complete: true,
            error: null,
            values: data
          }
        });
        if (cb) {
          cb(data)
        }
      })
      .catch(error => {
        console.error('COMMON_LOAD error: ', error)
        dispatch({
          type: 'COMMON_LOAD',
          key: key,
          value: {
            loading: false,
            complete: true,
            error: error,
            values: []
          }
        })
      });
  }
}

export default loadDBLambdaData;
