/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov
 */

const tracerRegex = /Tracer(\s+?-?\d+?)?/gi

/**
 * formatFullName
 * @params {Player} player, a player object to format full name from
 * @return {string} the player's full name
 */
export const formatFullName = (player) => {
    let name = ''
    let fname = ''
    let lname = ''

    if (player.first_name) {
        fname = player.first_name
    }
    else if (player.user && player.user.first_name) {
        fname = player.user.first_name
    }

    if (player.last_name) {
        lname = player.last_name
    }
    else if (player.user && player.user.last_name) {
        lname = player.user.last_name
    }

    if (fname.match(tracerRegex)) {
        fname = 'Tracer'
        lname = ''
        if (player.trace_number) {
            fname = `Tracer ${player.trace_number}`
        }
        else if (player.id) {
            fname = `Tracer ${player.id}`
        }
        else if (player.default_trace_number) {
            fname = `Tracer ${player.default_trace_number}`
        }
        else {
            //console.log("tn", player)
        }
    }

    name += fname
    name += ' '
    name += lname

    return name.trim()
}

/**
 * formatShortName
 * @params {player}, a player object to format first name from
 * @return {string} the player's first name
 */
export const formatShortName = (player) => {
    let name = ''
    let fname = ''
    let lname = ''

    if (player.first_name) {
        fname = player.first_name
    }
    else if (player.user && player.user.first_name) {
        fname = player.user.first_name
    }

    if (player.last_name) {
        lname = player.last_name
    }
    else if (player.user && player.user.last_name) {
        lname = player.user.last_name
    }

    if (fname.match(tracerRegex)) {
        fname = 'Tracer'
        lname = ''
        if (player.trace_number) {
            fname = `Tracer ${player.trace_number}`
        }
        else if (player.id) {
            fname = `Tracer ${player.id}`
        }
        else if (player.default_trace_number) {
            fname = `Tracer ${player.default_trace_number}`
        }
        else {
            //console.log("tn", player)
        }
    }

    name += fname.length > 0 ? fname.substring(0, 1)+'.' : ''
    name += ' '
    name += lname

    return name.trim()
}



/**
 * formatFirstName
 * @params {player}, a player object to format first name from
 * @return {string} the player's first name
 */
export const formatFirstName = (player) => {
    let fname = ''

    if (player.first_name) {
        fname = player.first_name
    }
    else if (player.last_name) {
        fname = player.last_name
    }
    else if (player.user && player.user.first_name && player.user.first_name.length > 0) {
        fname = player.user.first_name
    }
    else if (player.user && player.user.last_name) {
        fname = player.user.last_name
    }

    if (fname.match(tracerRegex)) {
        if (player.trace_number) {
            fname = `Tracer ${player.trace_number}`
        }
        else if (player.id) {
            fname = `Tracer ${player.id}`
        }
        else if (player.default_trace_number) {
            fname = `Tracer ${player.default_trace_number}`
        }
    }

    return fname.trim()
}

/**
 * formatLastName
 * @params {player}, a player object to format last name from
 * @return {string} the player's last name
 */
export const formatLastName = (player) => {
    let fname = ''
    let lname = ''

    if (player.first_name) {
        fname = player.first_name
    }
    else if (player.user && player.user.first_name && player.user.first_name.length > 0) {
        fname = player.user.first_name
    }

    if (!player.first_name && !(player.user && player.user.first_name)) {
        lname = ''
    }
    else if (player.last_name) {
        lname = player.last_name
    }
    else if (player.user && player.user.last_name) {
        lname = player.user.last_name
    }

    if (fname.match(tracerRegex)) {
        lname = ''
    }

    return lname.trim()
}

/**
 * formatAbbr
 * @params {player}, a player object to format abbr from
 * @return {string} the player's abbr
 */
export const formatAbbr = (player) => {
    let abbr = ''
    let fname = ''
    let lname = ''

    // First name
    if (player.first_name) {
        fname = player.first_name
    }
    else if (player.user && player.user.first_name && player.user.first_name.length > 0) {
        fname = player.user.first_name
    }

    // Last name
    if (player.last_name) {
        lname = player.last_name
    }
    else if (player.user && player.user.last_name && player.user.last_name.length > 0) {
        lname = player.user.last_name
    }

    // Abbr
    if (player.abbr) {
        abbr = player.abbr
    }
    else if (player.user && player.user.abbr) {
        abbr = player.user.abbr
    }

    // generate abbr
    if (abbr.length === 0) {
        if (fname && fname.length > 0) {
            abbr += fname[0]
        }
        if (lname && lname.length > 0) {
            abbr += lname[0]
        }
    }

    // tracer user
    if (fname.match(tracerRegex)) {
        if (player.trace_number) {
            abbr = `${player.trace_number.length < 2 ? '0' : '' }${player.trace_number}`
        }
        else if (player.id) {
            abbr = `${player.id.length < 2 ? '0' : '' }${player.id}`
        }
    }

    return abbr
}

/**
 * formatPlayerUnknown
 * @params {player}, a player object to format abbr from
 * @return {bool} true if this is a known player, false if this player is unkown/default tracer user
 */
export const formatPlayerUnknown = (player) => {
    let fname = ''

    // First name
    if (player.first_name) {
        fname = player.first_name
    }
    else if (player.user && player.user.first_name && player.user.first_name.length > 0) {
        fname = player.user.first_name
    }

    // tracer user
    return fname.match(tracerRegex) !== null
}

