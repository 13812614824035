/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov, Slobodan Djordjevic
 */
class WebApi {
    constructor() {
        this._scheme = 'https'
        this._host = process.env.REACT_APP_TRACE_ENV.includes('test') ?
            `soccer-${process.env.REACT_APP_TRACE_ENV}.traceup.com` : 'soccer.traceup.com';
        this._loggedIn = null
        this._online = null
        this._handleResponse = this._handleResponse.bind(this)
        this.setOnline = this.setOnline.bind(this)
        this.setOffline = this.setOffline.bind(this)
        this.logResponses = () => { return false }

        if (window) {
            window.addEventListener('online',  this.setOnline)
            window.addEventListener('offline', this.setOffline)
        }
        if (navigator && navigator.onLine != null) { // eslint-disable-line eqeqeq
            if (navigator.onLine) {
                this.setOnline()
            } else {
                this.setOffline()
            }
        }
    }

    setLoggedIn(val) {
        if (this._loggedIn !== val) {
            this._loggedIn = val
            var event = new CustomEvent('WebApi.loggedIn.changed', { detail: val })
            document.dispatchEvent(event)
        }
    }

    setOnline() {
        var val = true
        if (this._online !== val) {
            this._online = val
            var event = new CustomEvent('WebApi.online.changed', { detail: val })
            document.dispatchEvent(event)
        }
    }

    setOffline() {
        var val = false
        if (this._online !== val) {
            this._online = val
            var event = new CustomEvent('WebApi.online.changed', { detail: val })
            document.dispatchEvent(event)
        }
    }

    checkAuthStatus() {
        return new Promise((resolve) => {
            this.getTeam()
                .then(() => {
                    resolve()
                })
                .catch((err) => {
                    console.warn('CheckAuthStatus.ERROR', err)
                    resolve()
                })
        })
    }

    login(teamId, password) {
        return new Promise((resolve, reject) => {
            this._post('/webapp/teams/login', { soccer_team_id: teamId, password: password })
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    userLogin(creds) {
        return new Promise((resolve, reject) => {
            this._post('/webapp/users/login', creds)
                .then((json) => {
                    resolve(json)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    addTeam(data) {
        return new Promise((resolve, reject) => {
            this._post('/webapp/teams/create', data)
                .then((json) => {
                    resolve(json)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    updateTeam(teamId, data) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/teams/${teamId}`, data)
                .then((json) => {
                    resolve(json)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    addTeamToDivision(teamId, divisionId) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/divisions/${divisionId}/teams/create`, {soccer_team_id: teamId})
                .then((json) => {
                    resolve(json)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getUserInfo() {
        return new Promise((resolve, reject) => {
            this._get('/webapp/users/self')
                .then(user => {
                    if (user) {
                        resolve(user)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getUserInfo err', err)
                    reject(err)
                })
        })
    }

    getUserTeams() {
        return new Promise((resolve, reject) => {
            this._get('/webapp/users/self/teams')
                .then(teams => {
                    if (teams) {
                        resolve(teams)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getUserTeams err', err)
                    reject(err)
                })
        })
    }

    getUserDivisions() {
        return new Promise((resolve, reject) => {
            this._get('/webapp/users/self/divisions')
                .then(divisions => {
                    if (divisions) {
                        resolve(divisions)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getUserDivisions err', err)
                    reject(err)
                })
        })
    }

    getDivisionUsers(divisionId) {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/divisions/${divisionId}/members`)
                .then(users => {
                    if (users) {
                        resolve(users)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getDivisionUsers err', err)
                    reject(err)
                })
        })
    }

    getTeamUsers(teamId) {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/teams/${teamId}/members`)
                .then(users => {
                    if (users) {
                        resolve(users)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getTeamUsers err', err)
                    reject(err)
                })
        })
    }

    batchGetTeamsUsers(teamIdsList) {
        let batchReqs = teamIdsList.map((id) => {
            return {uri: `/webapp/teams/${id}/members`, method: 'GET'}
        })

        return new Promise((resolve, reject) => {
            this._post('/webapp/batch', {requests: JSON.stringify(batchReqs)})
                .then(data => {
                    if (data) {
                        resolve(data)
                    } else {
                        reject('batch_failed')
                    }
                })
                .catch(err => {
                    console.log('WebApi.batchGetTeamsUsers error', err)
                    reject('batch')
                })
        })
    }

    batchGetDivisionsTeams(divisionIds) {
        let batchReqs = divisionIds.map((id) => {
            return {uri: `/webapp/divisions/${id}/teams`, method: 'GET'}
        })

        return new Promise((resolve, reject) => {
            this._post('/webapp/batch', {requests: JSON.stringify(batchReqs)})
                .then(data => {
                    if (data) {
                        resolve(data)
                    } else {
                        reject('batch_failed')
                    }
                })
                .catch(err => {
                    console.log('WebApi.batchGetTeamsUsers error', err)
                    reject('batch')
                })
        })
    }

    getDivisionTeams(divisionId) {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/divisions/${divisionId}/teams`)
                .then(teams => {
                    if (teams) {
                        resolve(teams)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getDivisionTeams err', err)
                    reject(err)
                })
        })
    }

    deleteTeamFromDivision(divisionTeamId) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/division-teams/${divisionTeamId}/delete`)
                .then(data => {
                    if (data) {
                        resolve(data)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.deleteTeamFromDivision err', err)
                    reject(err)
                })
        })
    }

    deleteMemberFromDivision(divisionMemberId) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/division-members/${divisionMemberId}/delete`)
                .then(data => {
                    if (data) {
                        resolve(data)
                    } else {
                        reject('no_info')
                    }
                })
                .catch(err => {
                    console.log('WebApi.deleteMemberFromDivision err', err)
                    reject(err)
                })
        })
    }

    getTeamWithName(name) {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/teams/${name}`)
                .then(team => {
                    if (team) {
                        resolve(team)
                    } else {
                        reject('missing_team')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getTeamWithName err', err)
                    reject(err)
                })
        })
    }

    createTeam(name) {
        return new Promise((resolve, reject) => {
            this._post('/webapp/teams/create', { title: name })
                .then(team => {
                    if (team) {
                        resolve(team)
                    } else {
                        reject('missing_team')
                    }
                })
                .catch(err => {
                    console.log('WebApi.createTeam error', err)
                    reject(name)
                })
        })
    }

    getTeam(teamId = 'self') {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/teams/${teamId}`)
                .then((team) => {
                    if (team) {
                        resolve(team)
                    } else {
                        reject('missing_team')
                    }
                })
                .catch((err) => {
                    console.log('WebApi.getTeam err', err)
                    reject(err)
                })
        })
    }

    updateTeamMember(member) {
        let req = null
        if(member.team_player_id) {
            req = { uri: `/webapp/team-members/${member.team_player_id}`, method: 'POST'}
            const params = {
                is_player: member.is_player,
                is_coach: member.is_coach,
                is_videographer: member.is_videographer
            }
            req.params = params
        } else {
            req = { uri: `/webapp/teams/${member.team_id}/member/create`, method: 'POST'}
            const params = { user_id: member.user_id,
                is_player: member.is_player,
                is_coach: member.is_coach,
                is_videographer: member.is_videographer
            }
            req.params = params
        }

        return new Promise((resolve, reject) => {
            this._post(req.uri, req.params)
                .then(data => {
                    resolve(true)
                })
                .catch(err => {
                    console.log('WebApi.updateTeamMembers error', err)
                    reject('update team member failed')
                })
        })
    }

    batchUpdateTeamMembers(memberRolesArray) {
        let batchReqs = []
        for(let member of memberRolesArray) {
            if(member.team_player_id) {
                let req = { uri: `/webapp/team-members/${member.team_player_id}`, method: 'POST'}
                const params = {
                    is_player: member.is_player,
                    is_coach: member.is_coach,
                    is_videographer: member.is_videographer
                }
                req.params = params
                batchReqs.push(req)
            } else {
                let req = { uri: `/webapp/teams/${member.team_id}/member/create`, method: 'POST'}
                const params = { user_id: member.user_id,
                    is_player: member.is_player,
                    is_coach: member.is_coach,
                    is_videographer: member.is_videographer
                }
                req.params = params
                batchReqs.push(req)
            }
        }
        return new Promise((resolve, reject) => {
            this._post('/webapp/batch', {requests: JSON.stringify(batchReqs)})
                .then(data => {
                    if (data) {
                        resolve(data)
                    } else {
                        reject('batch_failed')
                    }
                })
                .catch(err => {
                    console.log('WebApi.batchUpdateTeamMembers error', err)
                    reject('batch')
                })
        })
    }

    createGame() {
        var date = new Date()
        var params = {
            date: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
        }
        return new Promise((resolve, reject) => {
            this._post('/webapp/games/create', params)
                .then((data) => {
                    if (data) {
                        return data.game_id
                    } else {
                        reject('failed create game')
                    }
                })
                .then((gameId) => {
                    this.getGame(gameId)
                        .then((game) => {
                            resolve(game)
                        })
                        .catch((err) => {
                            console.log('WebApi.createGame getGame err', err)
                            reject(err)
                        })
                })
                .catch((err) => {
                    console.log('WebApi.createGame err', err)
                    reject(err)
                })
        })
    }

    getGames(teamId = 'self') {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/teams/${teamId}/games`)
                .then(games => {
                    if (games) {
                        resolve(games)
                    } else {
                        reject('missing_games')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getGames error', err)
                    reject(err)
                })
        })
    }

    getTeamByName(teamName) {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/teams/${teamName}`)
                .then(team => {
                    if (team) {
                        resolve(team)
                    } else {
                        reject('missing_team')
                    }
                })
                .catch(err => {
                    console.log('WebApi.getTeamByName error', err)
                    reject(err)
                })
        })
    }

    getRandomPassphrase() {
        return new Promise((resolve, reject) => {
            this._get('/webapp/teams/random-password', {})
                .then((data) => {
                    console.log('WebApi.random-password: ' + data.access_password)
                    resolve(data.access_password)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }


    getGame(gameId) {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/games/${gameId}`, { })
                .then((game) => {
                    if (game) {
                        // Validate game data
                        if (game['approx_start_time'] == null || game['approx_start_time'].length < 1) {
                            game['approx_start_time'] = '12:00'
                            console.warn('WebApi.getGame game.approx_start_time not present, mocking in data')
                        }
                        if (game['approx_half_duration'] == null || game['approx_half_duration'] < 25 || game['approx_half_duration'] > 45) {
                            game['approx_half_duration'] = 45
                            console.warn('WebApi.getGame game.approx_half_duration not present, mocking in data')
                        }
                        if (game.home_team['score'] == null) {
                            game.home_team['score'] = 0
                            console.warn('WebApi.getGame game.home_team.score not present, mocking in data')
                        }
                        if (game.away_team['score'] == null) {
                            game.away_team['score'] = 0
                            console.warn('WebApi.getGame game.away_team.score not present, mocking in data')
                        }
                        if (game.away_team['jersey_color'] == null) {
                            game.away_team['jersey_color'] = ''
                            console.warn('WebApi.getGame game.away_team.jersey_color not present, mocking in data')
                        }
                        if (game.home_team['jersey_color'] == null) {
                            game.home_team['jersey_color'] = ''
                            console.warn('WebApi.getGame game.home_team.jersey_color not present, mocking in data')
                        }
                        if (! game['trace_case_id']) {
                            game['trace_case_id'] = ''
                            console.warn('WebApi.getGame game.trace_case_id not present, mocking in data')
                        }
                        var gdp = game.date.split('-')
                        var gtp = game.approx_start_time.split(':')
                        var y = parseInt(gdp[0], 10)
                        var m = parseInt(gdp[1], 10) - 1
                        var d = parseInt(gdp[2], 10)
                        var hh = parseInt(gtp[0], 10)
                        var mm = parseInt(gtp[1], 10)
                        var utcDate = Date.UTC(y, m, d, hh, mm)
                        var localDate = new Date(utcDate)
                        game.date = this._dateToString(localDate)
                        game.approx_start_time = this._dateToTimeString(localDate)
                        resolve(game)
                    } else {
                        reject('failed get game')
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getGamePlayers(gameId, teamShortName) {
        //Here we accept ONLY gameId
        return new Promise((resolve, reject) => {
            this._get(`/webapp/games/${gameId}/players`, {})
                .then((data) => {
                    if (data) {
                        let athletes = {}
                        data.forEach((item) => {
                            let athlete = {}
                            athlete.id = item.trace_number
                            athlete.user_id = item.user_id
                            athlete.first_name = item.first_name
                            athlete.last_name = item.last_name
                            athlete.abr = item.abbr
                            athlete.jersey = item.jersey_number
                            athlete.pos = item.position
                            athlete.team = teamShortName
                            if(item.first_name &&
                                (item.first_name.includes('Trace Number')
                                    || item.first_name.includes('Tracer'))) {
                                athlete.abr = athlete.id
                                athlete.unknown = true //mark player unknown
                            }
                            athletes[athlete.id] = athlete
                        })
                        resolve(athletes)
                    } else {
                        reject('failed')
                    }
                })
                .catch((err) => {
                    console.error('getGamePlayers.catch', err)
                    reject(err)
                })
        })
    }

    _dateToString(date) {
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        var d = date.getDate()
        var ms = m < 10 ? `0${m}` : `${m}`
        var ds = d < 10 ? `0${d}` : `${d}`
        return `${y}-${ms}-${ds}`
    }

    _dateToTimeString(date) {
        var hh = date.getHours()
        var mm = date.getMinutes()
        var hhs = hh < 10 ? `0${hh}` : `${hh}`
        var mms = mm < 10 ? `0${mm}` : `${mm}`
        return `${hhs}:${mms}`
    }

    updateGame(game, videoFilesUploaded = 0) {
        return new Promise((resolve, reject) => {

            // Format game time, convert from local to UTC
            var gdp = game.date.split('-')
            var gtp = game.approx_start_time.split(':')
            var y = parseInt(gdp[0], 10)
            var m = parseInt(gdp[1], 10) - 1
            var d = parseInt(gdp[2], 10)
            var hh = parseInt(gtp[0], 10)
            var mm = parseInt(gtp[1], 10)
            var localDate = new Date(y, m, d, hh, mm)
            var offset = localDate.getTimezoneOffset() * 60 * 1000
            var utcDate = new Date(localDate.getTime() + offset)

            var gameTime = this._dateToTimeString(utcDate)
            var gameDate = this._dateToString(utcDate)

            console.log('WebApi.updateGame')
            console.log('input', game.date, game.approx_start_time)
            console.log('local', localDate)
            console.log('utc', utcDate)
            console.log('gameDate', gameDate)
            console.log('gameTime', gameTime)


            var params = {
                home_team_jersey_color: game.home_team.jersey_color,
                date: gameDate,
                approx_start_time: gameTime,
                approx_half_duration: game.approx_half_duration
            }
            if (game.trace_case_id && game.trace_case_id.length > 0) {
                params['trace_case_id'] = game.trace_case_id
            }
            if (game.pi_id && game.pi_id.length > 0) {
                params['pi_id'] = game.pi_id
            }
            if (game.camera_pi_id && game.camera_pi_id.length > 0) {
                params['camera_pi_id'] = game.camera_pi_id
            }
            if (game.home_team.score > 0) {
                params['home_team_score'] = game.home_team.score
            } else {
                params['home_team_score'] = 0
            }
            if (videoFilesUploaded >= 0) {
                params['video_files_uploaded'] = videoFilesUploaded
            }
            if (game.away_team && game.away_team.team_id) {
                params['away_team_id'] = game.away_team.team_id
            }
            if (game.away_team && game.away_team.score != null) {
                params['away_team_score'] = game.away_team.score
            } else {
                params['away_team_score'] = 0
            }
            if (game.away_team && game.away_team.jersey_color) {
                params['away_team_jersey_color'] = game.away_team.jersey_color
            }
            if (game.camera_type) {
                params['camera_type'] = game.camera_type
            }
            if (game.tournament_id > 0) {
                params['tournament_id'] = game.tournament_id
            }

            this._post(`/webapp/games/${game.game_id}`, params)
                .then((data) => {
                    resolve(true)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    autoCompleteTeam(name) {
        return new Promise((resolve, reject) => {
            this._post('/webapp/teams/search', { term: name })
                .then((teams) => {
                    if (teams) {
                        resolve(teams)
                    } else {
                        reject('failed')
                    }
                })
                .catch((err) => {
                    console.log('auto teams err', err)
                    reject(err)
                })
        })
    }

    checkForSensorData(gameId) {
        return new Promise((resolve, reject) => {
            var params = {}
            this._get(`/webapp/games/${gameId}/archives-info`, params)
                .then((data) => {
                    if (data) {
                        resolve(data)
                    } else {
                        reject('failed')
                    }
                })
                .catch((err) => {
                    console.log('Sensor Data Archive Info ERROR', err)
                    reject(err)
                })
        })
    }

    checkCoachNotes(teamId, startTime, halfLength, gameFolder) {
        return new Promise((resolve, reject) => {
            var start = startTime.getTime()
            var dur = (20 + (halfLength*2)) * 60 * 1000
            var qs = `teamId=${teamId}&start=${start}&duration=${dur}&gameFolder=${gameFolder}`
            var url = `https://lapi.traceup.com/upload-${process.env.REACT_APP_TRACE_ENV}/game/process/coach?${qs}`
            var opts = {
                method: 'GET',
            }
            fetch(url, opts)
                .then((resp) => {
                    return resp.json()
                })
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    console.log('WebApi.checkCoachNotes err', err)
                    reject(err)
                })
        })
    }

    checkGameInfoFile(teamId, gameId) {
        return new Promise((resolve, reject) => {
            var url = `${process.env.REACT_APP_API}/teams/${teamId}/games/${teamId}-${gameId}/game_info.json`
            var opts = {
                method: 'GET',
            }
            fetch(url, opts)
                .then((resp) => {
                    return resp.json()
                })
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    console.log('WebApi.checkGameInfoFile err', err)
                    reject(err)
                })
        })
    }

    checkCameraSync(teamId, startTime, halfLength, gameFolder) {
        return new Promise((resolve, reject) => {
            var start = startTime.getTime()
            var dur = (20 + (halfLength*2)) * 60 * 1000
            var qs = `teamId=${teamId}&start=${start}&duration=${dur}&gameFolder=${gameFolder}`
            var url = `https://lapi.traceup.com/upload-${process.env.REACT_APP_TRACE_ENV}/game/process/camera?${qs}`
            var opts = {
                method: 'GET',
            }
            fetch(url, opts)
                .then((resp) => {
                    return resp.json()
                })
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    console.log('WebApi.checkCameraSync err', err)
                    reject(err)
                })
        })
    }

    postSlack(message, icon, thread) {
        return new Promise((resolve, reject) => {
            var params = {
                text: message,
                username: 'TeamsApp',
                icon_emoji: icon ? icon : ':trace-bot-happy:'
            }
            if (thread) {
                params['thread_ts'] = thread
            }
            var opts = {
                method: 'POST',
                body: JSON.stringify(params)
            }
            var url = `https://lapi.traceup.com/upload-${process.env.REACT_APP_TRACE_ENV}/slack/post`

            fetch(url, opts)
                .then(resp => {
                    return resp.json()
                })
                .then(json => {
                    console.log('slack: ', json)
                    resolve(json)
                })
                .catch(err => {
                    console.log(err)
                    resolve(false)
                })
        })
    }


    listVideos(gameFolder) {
        return new Promise((resolve, reject) => {
            var qs = `gameFolder=${gameFolder}`
            var url = `https://lapi.traceup.com/upload-${process.env.REACT_APP_TRACE_ENV}/game/videos/list?${qs}`
            var opts = {
                method: 'GET',
            }
            fetch(url, opts)
                .then((resp) => {
                    return resp.json()
                })
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    console.log('WebApi.listVideos err', err)
                    reject(err)
                })
        })
    }

    getClipTags(clipId, userId) {
        return new Promise((resolve, reject) => {
            let url = `https://lapi.traceup.com/upload-${process.env.REACT_APP_TRACE_ENV}/data/db-ro/query`;
            let opts = {
                method: 'POST',
                body: JSON.stringify({
                    "query":
                        [
                            {
                                "type": "query",
                                "table": "clip_tags",
                                data: {
                                    game_clip_id: clipId,
                                    user_id: userId
                                }
                            }
                        ]
                })
            };
            fetch(url, opts)
                .then((resp) => {
                    return resp.json()
                })
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    console.log('WebApi.getClipTags err', err)
                    reject(err)
                })
        })
    }

    getGeneralTagsList() {
        return new Promise((resolve, reject) => {
            let url = `https://lapi.traceup.com/internals-${process.env.REACT_APP_TRACE_ENV}/cliptags/do`;
            let opts = {
                method: 'POST',
                body: JSON.stringify({action: 'list'})
            };
            fetch(url, opts)
                .then((resp) => {
                    return resp.json()
                })
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    console.log('WebApi.getTagsList err', err)
                    reject(err)
                })
        })
    }

    ignoreVideoFile(gameFolder, file = null) {
        return new Promise((resolve, reject) => {
            var qs = `gameFolder=${gameFolder}`
            if (file != null) {
                qs += `&file=${file}`
            }
            var url = `https://lapi.traceup.com/upload-${process.env.REACT_APP_TRACE_ENV}/game/videos/ignore?${qs}`
            var opts = {
                method: 'GET',
            }
            fetch(url, opts)
                .then((resp) => {
                    return resp.json()
                })
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    console.log('WebApi.ignoreVideoFile err', err)
                    reject(err)
                })
        })
    }

    processGame(gameId) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/games/${gameId}/process`, {})
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log('processGame.catch', err)
                    reject(err)
                })
        })
    }

    getSeasonRoster(teamId) {
        return new Promise((resolve, reject) => {
            this._get('/webapp/teams/self/members', {})
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    console.log('WebApi.getSeasonRoster ERROR', err)
                    reject(err)
                })
        })
    }

    updateSeasonRosterPlayer(playerId, attr) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/team-members/${playerId}`, attr)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    console.log('updateSeasonRosterPlayer ERROR', err)
                    reject(err)
                })
        })
    }

    addSeasonRosterPlayer(teamName, attr) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/teams/${teamName}/member/create`, attr)
                .then((data) => {
                    resolve(data.team_player_id)
                })
                .catch(err => {
                    console.log('addSeasonRosterPlayer ERROR', err)
                    reject(err)
                })
        })
    }

    /**
     *
     * Log error to the remote service that will put it into the game folder
     *
     * Params:
     * logMessage - message to log
     * gameFullId - full id of the game e.g kjfrtsg8s-937
     **/
    logError(logMessage, gameFullId) {
        return new Promise((resolve, reject) => {
            const body = gameFullId ? {log: logMessage, gameId: gameFullId} : {log: logMessage}
            const opts = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
            const url = `https://lapi.traceup.com/upload-${process.env.REACT_APP_TRACE_ENV}/logger`

            fetch(url, opts)
                .then(resp => {
                    return resp.json()
                })
                .then(json => {
                    resolve(json)
                })
                .catch(err => {
                    console.log('WebApi.logger err', err)
                    reject(err)
                })
        })
    }

    getEmailWhitelist() {
        return new Promise((resolve, reject) => {
            const body = { action: 'list' }
            const opts = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
            const url = 'https://apkzbbs5q8.execute-api.us-east-1.amazonaws.com/default/tracebot-whitelist-toggle-api'

            fetch(url, opts)
                .then(resp => {
                    return resp.json()
                })
                .then(json => {
                    resolve(json)
                })
                .catch(err => {
                    console.log('WebApi.whitelist err', err)
                    reject(err)
                })
        })
    }

    updateUser(userId, userData) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/users/${userId}`, userData)
                .then((data) => {
                    resolve(data)
                })
                .catch(err => {
                    console.log('WebApi.updateUser err', err)
                    reject(err)
                })
        })
    }

    userLogout() {
        return new Promise((resolve, reject) => {
            this._post('/webapp/users/self/logout', {})
                .then(() => {
                    resolve()
                })
                .catch(err => {
                    console.log('WebApi.logoutUser err', err)
                    reject(err)
                })
        })
    }

    inviteMembersToDivision(divisionId, emailsArray) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/divisions/${divisionId}/members/invite`, {emails: JSON.stringify(emailsArray)})
                .then(() => {
                    resolve()
                })
                .catch(err => {
                    console.log('WebApi.inviteMembersToDivision err', err)
                    reject(err)
                })
        })
    }

    /**
     * Accept the invitation to the division by inviteId
     * @param inviteId
     * @param data {invite_code: "ewoi354tg", first_name: "aaa", last_name: "bbb", password: "ccc"}
     * @returns {Promise<any>}
     */
    acceptInvite(inviteId, data) {
        return new Promise((resolve, reject) => {
            // To make testing invites easier
            if (inviteId === '299') {
                resolve()
                return
            }
            this._post(`/webapp/division-invites/${inviteId}/accept`, data)
                .then(() => {
                    resolve()
                })
                .catch(err => {
                    console.log('WebApi.acceptInvite err', err)
                    reject(err)
                })
        })
    }

    getInviteInfo(inviteId) {
        return new Promise((resolve, reject) => {
            // To make testing invites possible
            if (inviteId === '299') {
                resolve({
                    first_name: 'briantbal+owner1',
                    invite_status: 'pending',
                    invite_status_update_time: '2018-10-19 18:25:28',
                    is_new_user: true,
                    last_name: null,
                    division_id: 27,
                    user_id: 826694
                })
                return
            }
            if (inviteId === '298') {
                resolve({
                    first_name: 'briantbal+owner1',
                    invite_status: 'pending',
                    invite_status_update_time: '2018-10-19 18:25:28',
                    is_new_user: false,
                    last_name: null,
                    division_id: 27,
                    user_id: 826694
                })
                return
            }
            this._get(`/webapp/division-invites/${inviteId}`, {})
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    console.log('WebApi.getInviteInfo ERROR', err)
                    reject(err)
                })
        })
    }

    /**
     * Accept the invitation to the team by inviteId
     * @param inviteId
     * @param data {invite_code: "ewoi354tg", first_name: "aaa", last_name: "bbb", password: "ccc"}
     * @returns {Promise<any>}
     */
    acceptTeamInvite(inviteId, data) {
        return new Promise((resolve, reject) => {
            this._post(`/webapp/team-invites/${inviteId}/accept`, data)
                .then(() => {
                    resolve()
                })
                .catch(err => {
                    console.log('WebApi.acceptTeamInvite err', err)
                    reject(err)
                })
        })
    }

    getTeamInviteInfo(inviteId) {
        return new Promise((resolve, reject) => {
            this._get(`/webapp/team-invites/${inviteId}`, {})
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    console.log('WebApi.getTeamInviteInfo ERROR', err)
                    reject(err)
                })
        })
    }

    _buildQueryString(params) {
        var str = ''
        var prefix = ''
        for (var key in params) {
            var val = params[key]
            str += `${prefix}${key}=${val}`
            prefix = '&'
        }
        return str
    }

    _buildFormData(params) {
        //Check if we have already had params as FormData then do nothing
        if(params instanceof FormData) return params

        var formData = new FormData()
        for (var key in params) {
            var val = params[key]
            formData.append(key, val)
        }
        return formData
    }

    _handleResponse(resp) {
        return new Promise((resolve, reject) => {
            if (resp == null) {
                this.setOnline(false)
                return
            }
            this.setOnline(true)

            if (!resp.ok) {
                reject('response is not ok')
                this.setLoggedIn(false)
                return
            }
            this.setLoggedIn(true)

            resp.json()
                .then((json) => {
                    if (!json.success) {
                        if (json.error.id === 'team_not_logged_in') {
                            this.setLoggedIn(false)
                        }
                        reject(json.error)
                        return
                    }
                    this.setLoggedIn(true)
                    this.logResponses(JSON.stringify(json))
                    resolve(json.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    _get(path, params) {
        return new Promise((resolve, reject) => {
            var query = this._buildQueryString(params)
            var opts = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': '*/*'
                }
            }
            var url = `${this._scheme}://${this._host}${path}`
            if (query.length > 0) {
                url = `${url}?${query}`
            }

            this.logResponses('GET ' + path + ' ' + JSON.stringify(params ? params : {}))
            fetch(url, opts)
                .then(this._handleResponse)
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    _post(path, params) {
        return new Promise((resolve, reject) => {

            var data = this._buildFormData(params)
            var opts = {
                method: 'POST',
                credentials: 'include',
                body: data,
                headers: {
                    'Accept': '*/*'
                }
            }
            var url = `${this._scheme}://${this._host}${path}`

            this.logResponses('POST ' + path + ' ' + JSON.stringify(params ? params : {}))
            fetch(url, opts)
                .then(this._handleResponse)
                .then((json) => {
                    resolve(json)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }


}


const sharedApi = new WebApi()
export let webApi = sharedApi
