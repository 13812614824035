/**
 *  Copyright AlpineReplay Inc, 2021. All rights reserved.
 *  Authors: Leonid Khramov, Slobodan Djordjevic
 */
import React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'trux';
import {Page, PageContent} from "../Navigation";
import './TeamGames.css';
import closeSvg from '../../assets/img/close-x.svg';
import PoweredByTrace from "../../components/PoweredBy";
import {Element, MatchesList} from 'trux';
import loadDBLambdaData from '../../actions/loadDBLambdaData';
import getLeagueName from '../../actions/getLeagueName';

class TeamGamesComponent extends React.Component {

    state = {
        teamTitle: "Loading...",
        leagueName: "-"
    };

    componentDidMount() {
        this.props.getLeagueName('webex_name_by_' + this.props.type + '_slug', {slug: this.props.slug});
        this.props.fetchData('matches_list', 'webex_games_played_in_' + this.props.type + '_by_team_id', {team_id: this.props.teamId, slug: this.props.slug});
        window.scrollTo(0,0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading !== this.props.loading && this.props.matches.length) {
            this.setState({teamTitle: this.props.matches[0].home_team_title, leagueName: this.props.leagueName});
        }
    }

    openTeamSelector() {
        this.props.history.push({
            pathname: '/' + this.props.type + '/' + this.props.slug,
            state: { find_focus: 'focus' }
        })
    }

    render() {
        return (
            <Page
                className="team-games-page"
                title={[this.state.leagueName, "Games", this.state.teamTitle ? this.state.teamTitle : "--"]}
            >
                <PageContent>
                    <Element className="team-games-content">
                        <Element className="pseudo-selector" onClick={(e)=> {
                            e.preventDefault();
                            this.openTeamSelector()
                        }}>
                            <span>{this.state.teamTitle}</span>
                            <img src={closeSvg} alt="close"/>
                        </Element>
                        <MatchesList key={'matches-list' + (this.props.matches.length ? this.props.matches[0].game_id : '')}
                                     loading={this.props.loading}
                                     games={this.props.matches}
                                     utmCampaign={this.props.utmCampaign}
                                     utmSource={this.props.utmSource}
                                     utmMedium={'game'} />
                        <PoweredByTrace/>
                    </Element>
                </PageContent>
            </Page>
        );
    }
}

const mapStateToProps = (state, props) => {
    let type = props.match.params.type;
    let teamId = props.match.params.team_id;
    let slug = props.match.params.slug;
    return {
        type: type,
        teamId: teamId,
        slug: slug,
        loading: state.matches_list.loading,
        matches: state.matches_list.values,
        leagueName: state.league_name.league
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        fetchData: (name, query, queryParams) => {
            dispatch(loadDBLambdaData(name, query, queryParams))
        },
        getLeagueName: (query, queryParams) => {
            dispatch(getLeagueName(query, queryParams))
        }
    }
}

const TeamGames = withRouter(connect(mapStateToProps, mapActionsToProps)(TeamGamesComponent));

export default TeamGames;
